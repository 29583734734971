<template>
  <feature-tour :steps="steps" name="edit-article-tour">
    <base-form
      v-slot="slotProps"
      :model="article"
      :rules="rules"
      :loading="loading"
      :form-submitted="formSubmitted"
      :submit-label="submitLabel"
      :mode="mode"
      :additional-validators="[
        {
          validator: checkCategories,
          onError: missingCategoryWarning
        }
      ]"
      model-name="Artikel"
    >
      <el-form-item id="tour-title" label="Titel" prop="title">
        <el-input v-model="slotProps.model.title" v-focus></el-input>
      </el-form-item>

      <el-form-item label="Untertitel" prop="subtitle">
        <el-input
          v-model="slotProps.model.subtitle"
          :maxlength="140"
          show-word-limit
        ></el-input>
      </el-form-item>

      <el-form-item id="tour-collection" label="Artikelsammlungen">
        <el-cascader
          v-model="slotProps.model.collection_ids"
          :options="articleCategoriesForCascader"
          :props="{ multiple: true, checkStrictly: true, emitPath: false }"
          placeholder="Artikelsammlungen auswählen"
        ></el-cascader>
      </el-form-item>

      <text-editor
        id="tour-content"
        v-model="slotProps.model.content"
        :loading="loading"
        :allow-images="true"
        @image-uploaded="addImage"
      ></text-editor>

      <el-form-item id="tour-institutions" label="Zugehörige Ansprechpersonen">
        <multiselect
          v-model="selectedInstitutions"
          placeholder="Einrichtungen durchsuchen…"
          label="full_name"
          track-by="id"
          :options="institutions"
          :multiple="true"
          select-label="Hinzufügen"
          deselect-label="Entfernen"
          selected-label="Ausgewählt"
        ></multiselect>
      </el-form-item>

      <el-form-item id="tour-published" label="Status">
        <el-switch
          v-model="slotProps.model.published"
          active-text="Veröffentlicht"
          inactive-text="Entwurf"
        ></el-switch>
      </el-form-item>

      <el-form-item
        v-if="!slotProps.model.original_id"
        label="Wiedervorlage"
        prop="remind_at"
      >
        <el-date-picker
          v-model="slotProps.model.remind_at"
          format="dd.MM.yyyy"
          :picker-options="pickerOptions"
          type="date"
          placeholder="Erinnerung am"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item
        v-if="!slotProps.model.original_id"
        id="tour-sharing"
        label="Mit anderen Jugendämtern in der Artikeldatenbank teilen"
      >
        <el-switch
          v-model="slotProps.model.shared"
          active-text="Ja"
          inactive-text="Nein"
        ></el-switch>
        <div style="margin-top:8px;">
          Durch das Teilen mit anderen Jugendämtern bestätigen Sie, dass diese
          Ihren Inhalt nutzen dürfen und Sie die
          <a
            href="https://studionomai.notion.site/Artikel-in-Artikeldatenbank-teilen-93809aebfe1a4d2e87db442821cb590d"
            target="_blank"
            >Voraussetzungen fürs Teilen von Artikeln</a
          >
          kennen.
        </div>
      </el-form-item>

      <el-form-item
        v-if="!slotProps.model.original_id && slotProps.model.shared"
        label="Schlagwörter für Artikeldatenbank"
      >
        <tag-input
          v-model="slotProps.model.tags_attributes"
          :options="articleTags"
          :loading="loading"
        />
      </el-form-item>
    </base-form>
  </feature-tour>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import BaseForm from "@/components/forms/BaseForm"
import InstitutionsRepository from "@/repositories/institutions_repository.js"
import ArticleTagsRepository from "@/repositories/article_tags_repository.js"
import GuideItemsRepository from "@/repositories/guide_items_repository.js"
import Multiselect from "vue-multiselect"
import TextEditor from "@/components/TextEditor"
import TagInput from "@/components/TagInput"
import FeatureTour from "@/components/FeatureTour"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  components: {
    BaseForm,
    TextEditor,
    TagInput,
    Multiselect,
    FeatureTour
  },
  mixins: [ApiErrorHandlerMixin],
  props: {
    article: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    submitLabel: {
      type: String,
      default: "Änderungen speichern"
    },
    formSubmitted: {
      type: Function,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      pickerOptions: {
        firstDayOfWeek: 1
      },
      articleCategories: [],
      institutions: [],
      articleTags: [],
      rules: {
        title: [
          { required: true, message: "Bitte Titel angeben", trigger: "blur" }
        ]
      },
      steps: [
        {
          text:
            "Bei dieser kleinen Tour zeigen wir Ihnen, wie man Artikel erstellt und bearbeitet. Im ersten Feld legen Sie den Titel für Ihren Artikel fest.",
          attachTo: {
            element: "#tour-title",
            on: "bottom"
          }
        },
        {
          text:
            "Hier wählen Sie aus, in welchen Artikelsammlungen Ihr Artikel erscheinen soll. Sie haben die Möglichkeit den Artikel in mehreren Artikelsammlungen anzuzeigen, dies erleichtert die Pflege der Inhalte.",
          attachTo: {
            element: "#tour-collection",
            on: "bottom"
          }
        },
        {
          text:
            "Hier bearbeiten Sie den Inhalt Ihres Artikels. Sie können den Text formatieren und Bilder einfügen. Wenn Sie eine Textstelle im Editor mit der Maus markieren, können Sie einen Link einfügen.",
          attachTo: {
            element: "#tour-content",
            on: "bottom"
          }
        },
        {
          text: `Wählen Sie hier aus Ihren eingetragenen Einrichtungen die passenden Ansprechpartner
            für den Artikel aus. Sie sollten wenn möglich keine regionalen Kontaktdaten (Telefon, E-Mail, usw.) im Artikeltext
            einfügen, sondern stattdessen hier die Ansprechpartner hinzufügen. So bleibt die Pflege
            der Artikel übersichtlicher und außerdem können Sie Inhalte besser mit anderen Kommunen teilen.
            <a target="_blank" href="https://familienapp.helpscoutdocs.com/article/76-artikel-in-artikeldatenbank-teilen">Mehr zum Teilen erfahren</a>`,
          attachTo: {
            element: "#tour-institutions",
            on: "bottom"
          }
        },
        {
          text: `Sobald Sie den Artikel auf "Veröffentlicht" schalten, ist er in der App für alle sichtbar.
          Solange der Artikel auf "Entwurf" steht, ist er in der App nicht sichtbar.`,
          attachTo: {
            element: "#tour-published",
            on: "bottom"
          }
        },
        {
          text: `Wählen Sie außerdem aus, ob Sie den Artikel mit anderen Kommunen in der Artikeldatenbank teilen
          möchten (dies ist nicht für Artikel möglich, die Sie aus einer anderen Region übernommen haben). Anschließend können Sie Schlagworte für den Artikel festlegen, so dass er in Artikeldatenbank
          einfacher gefunden werden kann. <a target="_blank" href="https://familienapp.helpscoutdocs.com/article/76-artikel-in-artikeldatenbank-teilen">Welche Artikel sollte ich teilen?</a>`
        }
      ]
    }
  },
  computed: {
    selectedInstitutions: {
      get: function() {
        return this.article.institutions
      },
      set: function(newValue) {
        Vue.set(
          this.article,
          "institution_ids",
          newValue.map(institution => institution.id)
        )
        Vue.set(this.article, "institutions", newValue)
      }
    },
    articleCategoriesForCascader() {
      return this.parseGuideItemTree(this.articleCategories)
    },
    ...mapGetters("auth", ["isSuperAdmin"])
  },
  async created() {
    let regionId = null
    if (this.$route.query.region_id && this.isSuperAdmin) {
      regionId = this.$route.query.region_id
    }

    GuideItemsRepository.getAll(regionId)
      .then(categories => {
        this.articleCategories = categories
      })
      .catch(error => {
        this.handleApiError(error, "Fehler Laden der Artikelsammlungen")
      })
    ArticleTagsRepository.getAll()
      .then(tags => {
        this.articleTags = tags
      })
      .catch(error => {
        this.handleApiError(error, "Fehler Laden der Schlagwörter")
      })
    InstitutionsRepository.getAll(regionId)
      .then(institutions => {
        this.institutions = institutions
      })
      .catch(error => {
        this.handleApiError(error, "Fehler Laden der Einrichungen")
      })
  },
  methods: {
    addImage({ signed_id, command }) {
      this.$emit("image-uploaded", {
        article: this.article,
        signed_id: signed_id,
        command: command
      })
    },
    parseGuideItemTree(categories) {
      let items = []
      let that = this
      categories.forEach(function(cat) {
        if (cat.type == "folder" || cat.type === "articles") {
          let item = {
            label: cat.name,
            value: cat.collection_id,
            disabled: cat.type === "folder",
            children: that.parseGuideItemTree(cat.children)
          }
          items.push(item)
        }
      })
      return items
    },
    checkCategories() {
      return this.article.collection_ids.length > 0
    },
    missingCategoryWarning() {
      this.$message({
        message: `Sie müssen mind. eine Artikelsammlung auswählen.`,
        type: "error",
        showClose: true
      })
    }
  }
}
</script>
